import React from "react"
import { navigate } from "gatsby"
import { CookiesProvider } from "react-cookie"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slide from "../components/Slide/Slide"
import Packaging from "../components/imageComponents/packaging"
import Mints from "../components/imageComponents/mints"
import PreRoll from "../components/imageComponents/preRoll"
import styles from "./indexStyles.module.scss"
import Button from "../components/Button/Button"
import KbMissouri from "../components/imageComponents/kbMissouri"
import RotatingText from "../components/RotatingText/RotatingText"
import JointsAndBuds from "../components/imageComponents/jointsAndBuds"
import Footer from "../components/Footer/Footer"
import EmailSignup from "../components/EmailSignup/EmailSignup"
import AgeGate from "../components/AgeGate/AgeGate"
import ParallaxWrapper from "../components/ParallaxWrapper/ParallaxWrapper"
import ParallaxChild from "../components/ParallaxChild/ParallaxChild"
import Buttermints from "../components/imageComponents/buttermints"
import PateDeFruit from "../components/imageComponents/pateDeFruit"
import Stroopwafel from "../components/imageComponents/stroopwafel"

import Container from "../components/Container/Container"
import Buds from "../components/imageComponents/buds"

const IndexPage = () => {
  return (
    <CookiesProvider>
      <AgeGate></AgeGate>
      <Layout>
        <SEO title="Kindbio" />

        <Slide>
          <div
            className={[
              styles.slide,
              styles.slide1,
              "parallaxMiddle",
              styles.heroBackground,
            ].join(" ")}
          >
            <Container
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div className={styles.heroTextWrapper}>
                <h1
                  className={[
                    "mb2",
                    styles.responsiveAlign,
                    styles.header,
                  ].join(" ")}
                >
                  Cultivating Cannabis Medicine with Kindness
                </h1>
                <p className={[styles.subheader, "mt2"].join(" ")}>
                  With science as our guide we develop cannabis therapies that
                  directly affect your well-being.
                </p>
              </div>
            </Container>
          </div>
        </Slide>
        <Slide>
          <div
            className={[styles.slide, styles.slide2, "parallaxMiddle"].join(
              " "
            )}
          >
            <div className={[styles.mints, "parallaxChild"].join(" ")}>
              <PateDeFruit />
            </div>

            <div className={styles.slide2TextWrapper}>
              <h1 className={["mb2"].join(" ")}>Sweet Relief</h1>
              <p className={[styles.textBox, "mt2"].join(" ")}>
                From chef-crafted edibles featuring local ingredients to the
                highest quality flower, pre-rolls and tinctures&mdash;KindBio
                products offer consistent therapeutic benefits YOU can count on.
                <br />
                <Button
                  className={"mt2"}
                  onClick={() => {
                    navigate("/products")
                  }}
                >
                  VIEW PRODUCTS
                </Button>
              </p>
            </div>
            <div className={[styles.joints, "parallaxChild"].join(" ")}>
              <Stroopwafel />
            </div>
          </div>
        </Slide>

        <div
          className={[styles.slide, styles.slide2, "mb5"].join(" ")}
          style={{ marginTop: "100px" }}
        >
          <div className={styles.kbMissouri}>
            <KbMissouri></KbMissouri>
          </div>
          <h1 className={[styles.header1, "mb2"].join(" ")}>
            Show Me Kindness
          </h1>
          <p className={[styles.textBox, "mt2"].join(" ")}>
            KindBio is a Missouri owned, grown and operated cultivator and
            manufacturer of medical marijuana. Our expert team is leading the
            country in molecular breeding technology to deliver the
            highest-quality medical marijuana.
            <br />
            <Button
              className={"mt2"}
              onClick={() => {
                navigate("/about")
              }}
            >
              MEET THE TEAM
            </Button>
          </p>
        </div>
        {/*
        <div
          style={{
            textAlign: "center",
            position: "relative",
            paddingTop: "calc(10vw + 2vh - 60px)",
          }}
          className="parallaxMiddle"
        >
          <h1
            style={{ position: "relative", top: "50px", marginBottom: "20px" }}
          >
            Cannabis Therapy Works
          </h1>
          <RotatingText></RotatingText>
          <div
            className={[styles.rotatingTextImageWrapper, "parallaxChild"].join(
              " "
            )}
          >
            <Buds></Buds>
          </div>
            </div>*/}

        <EmailSignup></EmailSignup>
        <Footer></Footer>
      </Layout>
    </CookiesProvider>
  )
}

export default IndexPage
